<template>
  <div style="position:relative;top: 0;">
    <div>
      <el-row class="tac">
        <el-col :span="4" >
          <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
          background-color="rgb(245,245,245)"
          router>

            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span>ChatABC</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="index">首页</el-menu-item>
                <el-menu-item index="chat">选项2</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-col>
        <el-col :span="20">
          <router-view></router-view>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>


export default {
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        }
    },

}
</script>
