<template>
  <div id="app" class="app1">
    <router-view v-if="isRouterAlive"></router-view>
    <a class="a" href="https://beian.miit.gov.cn/#/Integrated/index">
      闽ICP备2023014988号-1
    </a>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="scss">
.app1 {
  // background-color: #269b33;
  position: relative;
  top: 0px;
  margin: 0;
  height: 100%;
}
.a {
  //位于底部居中
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #269b33;
  border-radius: 5px;
  box-shadow: 0 0 10px #269b33;
}
</style>
